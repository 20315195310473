import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import McsTable from "./McsTable";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { SuccessIcon } from "components/icons/SuccessIcon";
import ApplicationLayout from "components/layout/ApplicationLayout";
import layoutStyle from "components/layout/layout.scss";
import { MCS_ROUTE } from "components/router/Routes";
import { hybridMcService } from "services/hybrid-mc/HybridMcService";
import { StoreState } from "store";
import buttonsStyle from "styles/buttons.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

export default function AllMcsView(): JSX.Element {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [registrationToken, setRegistrationToken] = useState<string>("");
    const [state, setState] = useState({ count: 0 });

    // TODO BCC-4316 and BCC-4487 - Need to add required logic for unregister and edit as required.
    // I have just set random return for onMcsUnregister and onMcsEdit just to create the flow

    function onMcsUnregister() {
        return setState((prevState) => ({ count: prevState.count }));
    }

    function onMcsEdit() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    const handleCopyToken = () => {
        hybridMcService
            .createMcRegistrationToken()
            .then((registrationToken) => {
                setRegistrationToken(registrationToken.token);
                return navigator.clipboard.writeText(registrationToken.token).then(() => {
                    toast(
                        <SuccessIcon
                            successClass={layoutStyle.customToastSuccessIcon}
                            color={theme.contentBackgroundColor}
                            text={t("Mcs.copyRegistrationCodeSuccess")}
                        />,
                        {
                            closeButton: (closeToastProps) => (
                                <CustomToastCloseButton
                                    closeToast={{ ...closeToastProps }}
                                    color={theme.iconFillColor}
                                />
                            ),
                            className: layoutStyle.customToastSuccessMessage,
                        }
                    );
                });
            })
            .catch((error) => logger.error("Couldn't copy to clipboard", error));
    };

    const viewActions = (
        <>
            <button
                className={buttonsStyle.primaryButtonWithoutIcon}
                onClick={handleCopyToken}
                data-testid={testIds.workArea.mcs.getRegistrationCodeButton}
                data-token={JSON.stringify({
                    token: registrationToken,
                })}
            >
                {t("Mcs.copyRegistrationCode")}
            </button>
        </>
    );

    return (
        <ApplicationLayout
            viewTitle={t("Mcs.table.title")}
            view={
                <McsTable
                    count={state.count}
                    initialMcsData={{
                        tenantUuid: "",
                        name: "",
                        description: "",
                    }}
                    onMcsEdit={onMcsEdit}
                    onMcsUnregister={onMcsUnregister}
                />
            }
            viewActions={viewActions}
            mainRoute={MCS_ROUTE}
        />
    );
}
