import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";

import CreateBootableUsbDrive from "./CreateBootableUsbDrive";
import PreConfigureStep from "./PreConfigureStep";
import ArrowButton from "components/home/ArrowButton";
import style from "components/home/bde-home-page/getting-started.scss";
import ChooseWorkflowTemplate from "components/home/choose-workflow-template/ChooseWorkflowTemplate";
import StepTwoStatusBadgeIcon from "components/icons/StepTwoStatusBadge";
import { SuccessIcon } from "components/icons/SuccessIcon";
import { checkIfMenuExpanded, isStepCompleted, WorkflowTemplateData } from "domain/onboardings";
import { StoreState } from "store";

interface Props {
    lastCompletedStepIndex: number;
    setLastCompletedStepIndex: (value: number) => void;
    defaultWorkflowTemplate: WorkflowTemplateData;
    requestFailureMessage?: string;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const BdeSteps = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [showContent, setShowContent] = React.useState<boolean>(false);
    const [closeInfoDialog, setCloseInfoDialog] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const preConfigureMenuExpanded = checkIfMenuExpanded(props.lastCompletedStepIndex, 1);

    React.useEffect(() => {
        if (preConfigureMenuExpanded) {
            setShowContent(true);
        }
    }, [preConfigureMenuExpanded]);

    React.useEffect(() => {
        if (success) {
            props.setLastCompletedStepIndex(1);
        }
    }, [success]);
    return (
        <>
            <ChooseWorkflowTemplate
                menuExpanded={checkIfMenuExpanded(props.lastCompletedStepIndex, 0)}
                stepCompleted={isStepCompleted(0, props.lastCompletedStepIndex)}
                stepActive={true}
                setLastCompletedStepIndex={props.setLastCompletedStepIndex}
                defaultWorkflowTemplate={props.defaultWorkflowTemplate}
                requestFailureMessage={props.requestFailureMessage}
            />
            {!showContent && closeInfoDialog && (
                <div className={success ? style.successColor : style.stepsToFollowLayout}>
                    <div className={style.stepWidthAlignment}>
                        {success ? <SuccessIcon color={theme.contentBackgroundColor} /> : <StepTwoStatusBadgeIcon />}
                    </div>
                    <div className={style.stepHeading}>
                        {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.title")}
                    </div>
                    <ArrowButton success={success} showStep={showContent} clickEvent={setShowContent} />
                </div>
            )}
            {showContent && (
                <PreConfigureStep
                    setCloseInfoDialog={setCloseInfoDialog}
                    setShowContent={setShowContent}
                    setSuccess={setSuccess}
                    success={success}
                />
            )}
            <CreateBootableUsbDrive
                menuExpanded={true}
                stepCompleted={false}
                stepActive={false}
                setCloseInfoDialog={setCloseInfoDialog}
                setShowContent={setShowContent}
                setSuccess={setSuccess}
            />
        </>
    );
};

export default connector(BdeSteps);
