import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "components/home/bde-home-page/getting-started.scss";
import StepCompletedText from "components/home/StepCompletedText";
import TemplateViewImage from "components/icons/TemplateViewImage";
import { WorkflowTemplateData } from "domain/onboardings";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    stepCompleted?: boolean;
    showWorflowTemplateView: (value: boolean) => void;
    isStepCompleted: (value: boolean) => void;
    showNextStep: (value: boolean) => void;
    setHideSecondStep?: (value: boolean) => void;
    defaultWorkflowTemplate: WorkflowTemplateData;
    requestFailureMessage?: string;
}

const WorkflowTemplateSubStep = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const stepCompleted = () => {
        props.showWorflowTemplateView(false);
        props.isStepCompleted(true);
        props.showNextStep(true);
        props.setHideSecondStep && props.setHideSecondStep(true);
    };

    return (
        <>
            <div className={style.inProgressInfoBox}>
                <div className={style.inProgressDescription}>
                    {t("Onboarding.gettingStartedFlow.workflowStep.description1")}
                </div>
                <div className={style.inProgressDescription}>
                    {t("Onboarding.gettingStartedFlow.workflowStep.description2")}
                </div>
            </div>
            <div className={style.previewTemplateInfoText}>
                {t("Onboarding.gettingStartedFlow.workflowStep.previewTemplateInfoText")}
                <div className={style.previewTemplateBox}>
                    <TemplateViewImage />
                    <div className={style.previewTemplateBlock}>
                        <div className={style.previewTemplateHeading}>{props.defaultWorkflowTemplate.name}</div>
                        <div>{props.defaultWorkflowTemplate.description}</div>
                        <div>
                            <button className={style.previewTemplateButton}>
                                {t("Onboarding.gettingStartedFlow.workflowStep.previewTemplate.button")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.templateSelectButtonLayout}>
                {props.stepCompleted ? (
                    <StepCompletedText
                        successText={t(
                            "Onboarding.gettingStartedFlow.workflowStep.previewTemplate.defaultTemplateSelected"
                        )}
                    />
                ) : (
                    <button
                        className={classNames(buttons.primaryButtonWithoutIcon, style.templateSelectButton)}
                        onClick={stepCompleted}
                    >
                        {t("Onboarding.gettingStartedFlow.workflowStep.defaultTemplateSelectButton")}
                    </button>
                )}
                {/* <button
                    className={classNames(buttons.secondaryButtonWithoutIcon, style.templateSelectButton)} */}
                {/* onClick={() => { */}
                {/* TODO BCC-4403: Implement click handler to select workflow template */}
                {/* }} */}
                {/* > */}
                {/* {t("Onboarding.gettingStartedFlow.workflowStep.viewAllTemplateButton")}
                </button> */}
            </div>
        </>
    );
};

export default connector(WorkflowTemplateSubStep);
