import * as React from "react";

const StepThreeStatusBadgeIcon = (): JSX.Element => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="20" height="20" rx="10" stroke="#606060" strokeWidth="1.5" strokeDasharray="5 5" />
        <path
            d="M7.744 8.73C7.804 8.33 7.916 7.978 8.08 7.674C8.244 7.37 8.45 7.116 8.698 6.912C8.946 6.704 9.23 6.548 9.55 6.444C9.874 6.34 10.222 6.288 10.594 6.288C10.966 6.288 11.308 6.342 11.62 6.45C11.932 6.554 12.198 6.704 12.418 6.9C12.642 7.092 12.816 7.324 12.94 7.596C13.064 7.868 13.126 8.168 13.126 8.496C13.126 8.772 13.092 9.016 13.024 9.228C12.956 9.44 12.858 9.626 12.73 9.786C12.606 9.946 12.454 10.082 12.274 10.194C12.094 10.302 11.892 10.392 11.668 10.464C12.216 10.616 12.628 10.864 12.904 11.208C13.18 11.548 13.318 11.98 13.318 12.504C13.318 12.908 13.242 13.272 13.09 13.596C12.938 13.916 12.73 14.188 12.466 14.412C12.202 14.632 11.894 14.802 11.542 14.922C11.194 15.038 10.822 15.096 10.426 15.096C9.974 15.096 9.586 15.042 9.262 14.934C8.938 14.822 8.662 14.668 8.434 14.472C8.206 14.272 8.016 14.034 7.864 13.758C7.716 13.478 7.59 13.172 7.486 12.84L7.996 12.624C8.128 12.568 8.256 12.552 8.38 12.576C8.504 12.6 8.594 12.67 8.65 12.786C8.71 12.906 8.778 13.042 8.854 13.194C8.934 13.342 9.04 13.484 9.172 13.62C9.304 13.752 9.468 13.864 9.664 13.956C9.864 14.048 10.114 14.094 10.414 14.094C10.702 14.094 10.954 14.048 11.17 13.956C11.386 13.86 11.566 13.738 11.71 13.59C11.854 13.438 11.962 13.27 12.034 13.086C12.106 12.902 12.142 12.72 12.142 12.54C12.142 12.316 12.114 12.11 12.058 11.922C12.002 11.734 11.894 11.574 11.734 11.442C11.578 11.306 11.36 11.2 11.08 11.124C10.8 11.048 10.436 11.01 9.988 11.01V10.158C10.356 10.154 10.666 10.116 10.918 10.044C11.174 9.972 11.38 9.872 11.536 9.744C11.692 9.616 11.804 9.464 11.872 9.288C11.944 9.112 11.98 8.916 11.98 8.7C11.98 8.464 11.944 8.258 11.872 8.082C11.8 7.906 11.698 7.76 11.566 7.644C11.438 7.524 11.284 7.436 11.104 7.38C10.928 7.32 10.736 7.29 10.528 7.29C10.32 7.29 10.128 7.32 9.952 7.38C9.776 7.44 9.62 7.524 9.484 7.632C9.348 7.74 9.234 7.868 9.142 8.016C9.05 8.164 8.984 8.324 8.944 8.496C8.888 8.648 8.816 8.75 8.728 8.802C8.644 8.854 8.522 8.866 8.362 8.838L7.744 8.73Z"
            fill="#606060"
        />
    </svg>
);

export default StepThreeStatusBadgeIcon;
