// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Vq81cMiXp-eSRCwTkgXNU{box-sizing:border-box;display:flex;flex-direction:column;justify-content:space-between;padding:12px;gap:12px;width:248px;background:#fafafa;border:1px solid #f7f7f7;border-radius:8px;min-height:104px}._1gdtzDu-m7L-AcT-sMuR9O{display:flex;flex-direction:row;justify-content:space-between}._1a_fPelUM1721kAps1nmO9{font-style:normal;font-weight:700;font-size:14px;line-height:20px;color:#303030;flex:1 1 auto}._1t5pZU31vVhdvuTJBJ4YvA{flex:0 0 auto;font-style:normal;font-weight:400;font-size:14px;line-height:20px;color:#606060;margin-left:8px;max-width:76px}._3a1Ad9l23otTlBzfCgmZeK{display:flex;flex-direction:row;justify-content:flex-start}.zeRgPgdoxRXXNuEhmP-z1{align-items:center;display:flex;position:relative}.zeRgPgdoxRXXNuEhmP-z1>*{padding:5px}", ""]);
// Exports
exports.locals = {
	"additionCard": "_2Vq81cMiXp-eSRCwTkgXNU",
	"topRow": "_1gdtzDu-m7L-AcT-sMuR9O",
	"name": "_1a_fPelUM1721kAps1nmO9",
	"version": "_1t5pZU31vVhdvuTJBJ4YvA",
	"bottomRow": "_3a1Ad9l23otTlBzfCgmZeK",
	"manualDownloadsWrapper": "zeRgPgdoxRXXNuEhmP-z1"
};
module.exports = exports;
