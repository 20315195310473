import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";

import Button from "components/button/Button";
import ArrowButton from "components/home/ArrowButton";
import style from "components/home/bde-home-page/getting-started.scss";
import { displayStepCompletionIcon } from "components/home/bmde-home-page/GettingStarted";
import CircleNumber from "components/home/CircleWithNumber";
import StepCompletedText from "components/home/StepCompletedText";
import ExportIcon from "components/icons/ExportIcon";
import Info from "components/icons/Info";
import Tooltip from "components/tooltip/Tooltip";
import { Versions } from "domain/softwarePackages";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    menuExpanded: boolean;
    stepCompleted: boolean;
    stepActive: boolean;
    licenses: Versions[];
    setLastCompletedStepIndex: (value: number) => void;
    stepClickable: boolean;
}

interface StepAction {
    description: string;
    label: string;
    onClick: () => void;
    variant?: "bmdeIso" | "bmdeUSBCreator";
}

interface Step {
    description: string;
    actions?: StepAction[];
    icon?: string;
    tooltip?: string;
}

const CreateBootableUsb = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [showContent, setShowContent] = React.useState<boolean>(false);
    const [closeInfoDialog, setCloseInfoDialog] = React.useState(true);
    const [success, setSuccess] = React.useState(false);

    const setCreateUsbStepSuccess = (newValue: boolean) => {
        if (newValue !== success) {
            props.setLastCompletedStepIndex(1);
        }
        setSuccess(newValue);
    };

    React.useEffect(() => {
        if (props.menuExpanded && props.stepClickable) {
            setShowContent(true);
        }
    }, [props.menuExpanded]);

    const handleISODownload = () => {
        // TODO BCC-4424 On click allow download
        // props.licenses.licenses?.forEach()
    };

    const handleUSBCreatorInstall = () => {
        // TODO BCC-4424 On click allow download
        // props.licenses.licenses?.forEach()
    };

    const usbCreationSteps: Step[] = [
        {
            description: "",
            actions: [
                {
                    description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.downloadISO"),
                    label: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.downloadISOButton"),
                    onClick: handleISODownload,
                    variant: "bmdeIso",
                },
                {
                    description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.downloadUSBCreator"),
                    label: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.usbCreatorButton"),
                    onClick: handleUSBCreatorInstall,
                    variant: "bmdeUSBCreator",
                },
            ],
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.insertUSB"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.bootableUSBStep"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.selectUSB"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.addISO"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.createBootableUSB"),
            tooltip: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.createUsbTooltip"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.done"),
        },
    ];

    return (
        <div>
            {!showContent && closeInfoDialog && (
                <div
                    className={
                        props.menuExpanded
                            ? style.stepInProgress
                            : props.stepCompleted
                            ? style.successColor
                            : style.stepsToFollowLayout
                    }
                >
                    <div className={style.stepWidthAlignment}>
                        {displayStepCompletionIcon(
                            props.menuExpanded,
                            props.stepCompleted,
                            2,
                            props.stepActive,
                            theme.defaultStarColor,
                            theme.whiteColor,
                            theme.secondaryWarningBackgroundColor,
                            theme.descriptionTextColor
                        )}
                    </div>
                    <div className={style.stepHeading}>
                        {t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.title")}
                    </div>
                    <ArrowButton
                        success={success}
                        showStep={showContent}
                        clickEvent={() => {
                            if (props.stepClickable) {
                                setShowContent(true);
                            }
                        }}
                    />
                </div>
            )}
            {showContent && (
                <div
                    className={style.stepInProgress}
                    onClick={() => {
                        if (props.stepCompleted) {
                            setShowContent(false);
                        }
                    }}
                >
                    <div className={style.subStepsToFollowLayout}>
                        <div className={style.stepWidthAlignment}>
                            {displayStepCompletionIcon(
                                props.menuExpanded,
                                props.stepCompleted,
                                2,
                                props.stepActive,
                                theme.defaultStarColor,
                                theme.whiteColor,
                                theme.secondaryWarningBackgroundColor,
                                theme.descriptionTextColor
                            )}
                        </div>
                        <div className={style.subStepsHeading}>
                            {t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.title")}
                        </div>
                    </div>
                    <div className={style.stepsContainer}>
                        <div className={style.alertBox}>
                            <Info borderColor={theme.contentBackgroundColor} color={theme.sortIconActiveColor} />
                            {t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.prepareYourUSB")}
                        </div>
                        {usbCreationSteps.map((step, index) => (
                            <div key={index} className={style.subStepsToFollowLayout}>
                                <CircleNumber number={index + 1} subStep={true} />
                                {!step.actions && <div className={style.stepPadding}>{step.description}</div>}
                                {step.tooltip && (
                                    <Tooltip content={step.tooltip} placement={"top"}>
                                        <div>
                                            <Info
                                                borderColor={theme.contentBackgroundColor}
                                                color={theme.sortIconActiveColor}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                                {step.actions && step.actions.length > 0 && (
                                    <div className={classNames(style.columnLayout)}>
                                        {/* Container for columns */}
                                        {step.actions.map((action, actionIndex) => (
                                            <div key={actionIndex} className={classNames(style.actionColumn)}>
                                                <div>{action.description}</div>
                                                <button onClick={action.onClick} className={style.stepActionButton}>
                                                    {action.label}
                                                    <ExportIcon size={24} color={theme.iconFillColor} />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className={style.markComplete}>
                        {props.stepCompleted ? (
                            <StepCompletedText
                                successText={t(
                                    "Onboarding.gettingStartedFlow.workflowStep.previewTemplate.defaultTemplateSelected"
                                )}
                            />
                        ) : (
                            <Button
                                variant={"PRIMARY"}
                                className={classNames(buttons.primaryButtonWithoutIcon)}
                                onClick={() => {
                                    setCloseInfoDialog(true), setShowContent(false), setCreateUsbStepSuccess(true);
                                }}
                            >
                                {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.markComplete")}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default connector(CreateBootableUsb);
