import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import style from "components/home/bde-home-page/getting-started.scss";
import product from "components/home/productcard/productcard.scss";
import { StoreState } from "store";

import download from "assets/images/icons/productIcons/download.svg";

interface Props {
    titleKey: string;
    descriptionKey: string;
    onDownload: () => void;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
});

const connector = connect(mapState);

const ProductCard = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={style.productCard}>
            <div className={product.productCardWrapper}>
                <strong>{t(props.titleKey)}</strong>
                <div className={product.buttonWrapper}>
                    <button onClick={props.onDownload}>
                        <img src={download} alt="download" />
                    </button>
                </div>
            </div>
            <div className={product.description}>{t(props.descriptionKey)}</div>
        </div>
    );
};

export default connector(ProductCard);
