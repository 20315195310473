import React from "react";

const HalfProgressIcon = (): JSX.Element => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
                stroke="#FFC915"
                strokeWidth="2"
            />
            <path
                d="M10 4C10.7879 4 11.5681 4.15519 12.2961 4.45672C13.0241 4.75825 13.6855 5.20021 14.2426 5.75736C14.7998 6.31451 15.2417 6.97595 15.5433 7.7039C15.8448 8.43185 16 9.21207 16 10C16 10.7879 15.8448 11.5681 15.5433 12.2961C15.2417 13.0241 14.7998 13.6855 14.2426 14.2426C13.6855 14.7998 13.0241 15.2417 12.2961 15.5433C11.5681 15.8448 10.7879 16 10 16L10 10L10 4Z"
                fill="#FFC915"
            />
        </svg>
    );
};

export default HalfProgressIcon;
