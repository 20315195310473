import * as React from "react";

const StepTwoStatusBadgeIcon = (): JSX.Element => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="20" height="20" rx="10" stroke="#606060" strokeWidth="1.5" strokeDasharray="5 5" />
        <path
            d="M12.916 13.89C13.044 13.89 13.144 13.928 13.216 14.004C13.288 14.076 13.324 14.172 13.324 14.292V15H7.54V14.598C7.54 14.518 7.556 14.434 7.588 14.346C7.62 14.254 7.674 14.172 7.75 14.1L10.474 11.37C10.702 11.142 10.908 10.922 11.092 10.71C11.276 10.498 11.432 10.288 11.56 10.08C11.688 9.868 11.786 9.652 11.854 9.432C11.926 9.212 11.962 8.98 11.962 8.736C11.962 8.496 11.924 8.286 11.848 8.106C11.772 7.922 11.668 7.77 11.536 7.65C11.404 7.53 11.248 7.44 11.068 7.38C10.888 7.32 10.694 7.29 10.486 7.29C10.278 7.29 10.086 7.32 9.91 7.38C9.738 7.44 9.584 7.524 9.448 7.632C9.312 7.74 9.198 7.868 9.106 8.016C9.014 8.164 8.948 8.324 8.908 8.496C8.852 8.648 8.78 8.75 8.692 8.802C8.608 8.854 8.484 8.866 8.32 8.838L7.708 8.73C7.764 8.33 7.874 7.978 8.038 7.674C8.202 7.37 8.408 7.116 8.656 6.912C8.904 6.704 9.188 6.548 9.508 6.444C9.832 6.34 10.18 6.288 10.552 6.288C10.928 6.288 11.274 6.344 11.59 6.456C11.91 6.564 12.186 6.722 12.418 6.93C12.65 7.138 12.832 7.392 12.964 7.692C13.096 7.988 13.162 8.324 13.162 8.7C13.162 9.02 13.114 9.318 13.018 9.594C12.926 9.866 12.798 10.126 12.634 10.374C12.474 10.622 12.286 10.864 12.07 11.1C11.858 11.332 11.632 11.568 11.392 11.808L9.208 14.04C9.376 13.996 9.544 13.96 9.712 13.932C9.884 13.904 10.046 13.89 10.198 13.89H12.916Z"
            fill="#606060"
        />
    </svg>
);

export default StepTwoStatusBadgeIcon;
