import * as React from "react";
import { useTranslation } from "react-i18next";

import Home from "./Home";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { HOME_ROUTE_WRAPPER } from "components/router/Routes";

export default function SupportAndHelpView(): JSX.Element {
    const { t } = useTranslation();

    return (
        <ApplicationLayout
            viewTitle={t("Routes.yourProducts")}
            view={<Home />}
            viewContainer={false}
            mainRoute={HOME_ROUTE_WRAPPER}
        />
    );
}
