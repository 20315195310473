import classNames from "classnames";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import ErrorMessage from "components/error-message/ErrorMessage";
import BdeSteps from "components/home/bde-home-page/BdeSteps";
import style from "components/home/bde-home-page/getting-started.scss";
import BmdeSteps from "components/home/bmde-home-page/BmdeSteps";
import ProgressBar from "components/home/ProgressBar";
import IconButton from "components/icon-button/IconButton";
import LeftArrow from "components/icons/LeftArrow";
import { subpageContext } from "components/layout/ApplicationLayout";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Heading from "components/typography/heading/Heading";
import {
    displayProgressBarProgress,
    getLastCompletedStepIndexForBde,
    getLastCompletedStepIndexForBmde,
    mapCompletedStepToBdeOnboardingStep,
    mapCompletedStepToBmdeOnboardingStep,
    OnboardingProductType,
    toWorkflowTemplateData,
    WorkflowTemplateData,
} from "domain/onboardings";
import { Versions } from "domain/softwarePackages";
import { templateService } from "services/workflows/TemplateService";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    product: string;
    visible: boolean;
    title: string;
    extraHeaderText?: string;
    description: string;
    footerVisibility?: boolean;
    buttons?: React.ReactNode;
    children?: React.ReactNode;
    loading?: boolean;
    primaryButtonRequired?: boolean;
    handleRefresh?: (value: boolean) => void;
    lastCompletedStep: string;
    hasIbr: boolean;
    licenses: Versions[];
}

export const GettingStartedLayout = (props: Props) => {
    const { t } = useTranslation();
    const context = React.useContext(subpageContext);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const bmdeStep = mapCompletedStepToBmdeOnboardingStep(props.lastCompletedStep);
    const bdeStep = mapCompletedStepToBdeOnboardingStep(props.lastCompletedStep);
    const lastCompletedBmdeStep = !bmdeStep ? -1 : getLastCompletedStepIndexForBmde(bmdeStep);
    const lastCompletedBdeStep = !bmdeStep ? -1 : getLastCompletedStepIndexForBde(bdeStep);
    const [lastCompletedStepIndexBmde, setLastCompletedStepIndexBmde] = React.useState<number>(lastCompletedBmdeStep);
    const [lastCompletedStepIndexBde, setLastCompletedStepIndexBde] = React.useState<number>(lastCompletedBdeStep);
    const [progressValueBmde, setProgressValueBmde] = React.useState<number>(10);

    React.useEffect(() => {
        setProgressValueBmde(displayProgressBarProgress(lastCompletedStepIndexBmde, progressValueBmde));
    }, [lastCompletedStepIndexBmde]);
    const [progressValueBde, setProgressValueBde] = React.useState<number>(10);
    const defaultTemplateTable: WorkflowTemplateData = { description: "", name: "" };
    const abortController = new AbortController();
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [bmdeDefaultWorkflowTemplate, setBmdeDefaultWorkflowTemplate] =
        React.useState<WorkflowTemplateData>(defaultTemplateTable);

    const handleCloseClick = () => {
        context?.setSubpage({
            content: undefined,
        });
    };

    const fetchData = (abortController: AbortController) => {
        templateService
            .fetchWorkflowTemplates(
                "",
                "",
                "",
                "",
                [],
                [],
                [],
                true,
                "",
                "",
                "",
                false,
                "",
                props.product === OnboardingProductType.BMDE ? OnboardingProductType.BMDE : OnboardingProductType.BDE,
                abortController
            )
            .then((data) => {
                if (data?.workflowTemplatesTableData.length >= 0) {
                    setBmdeDefaultWorkflowTemplate(toWorkflowTemplateData(data.workflowTemplatesTableData[0]));
                }
                setRequestFailureMessage("");
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("workflowTemplatesManager.template.requestFailed"));
                }
            });
    };
    React.useEffect(() => {
        if (props.hasIbr) {
            fetchData(abortController);
        }
    }, [props.hasIbr]);

    React.useEffect(() => {
        setProgressValueBde(displayProgressBarProgress(lastCompletedStepIndexBde, progressValueBde));
    }, [lastCompletedStepIndexBde]);
    return (
        <div className={style.pageLayout}>
            {props.loading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <div className={classNames(style.layoutPadding, style.pageContainer, style.page)}>
                        <div className={style.pageHeader}>
                            {props.extraHeaderText ? (
                                <div className={style.layoutTopText}>{props.extraHeaderText + " /"}</div>
                            ) : null}
                            <div className={style.headingLayout}>
                                <IconButton
                                    onClick={handleCloseClick}
                                    testId={testIds.common.subpageView.backToPrimaryViewButton}
                                >
                                    <LeftArrow color={theme.secondaryButtonForegroundColor} />
                                </IconButton>
                                <Heading className={style.headingText} tag="h1" variant="H1">
                                    {props.title}
                                </Heading>
                            </div>
                            <div className={style.layoutTopText}>{props.description}</div>
                        </div>
                        <ProgressBar
                            progressValue={
                                props.product === OnboardingProductType.BMDE ? progressValueBmde : progressValueBde
                            }
                        />
                        <div>
                            {props.product === OnboardingProductType.BDE ? (
                                <BdeSteps
                                    lastCompletedStepIndex={lastCompletedStepIndexBde}
                                    setLastCompletedStepIndex={setLastCompletedStepIndexBde}
                                    defaultWorkflowTemplate={bmdeDefaultWorkflowTemplate}
                                    requestFailureMessage={requestFailureMessage}
                                />
                            ) : (
                                <BmdeSteps
                                    lastCompletedStepIndex={lastCompletedStepIndexBmde}
                                    setLastCompletedStepIndex={setLastCompletedStepIndexBmde}
                                    licenses={props.licenses}
                                    defaultWorkflowTemplate={bmdeDefaultWorkflowTemplate}
                                    requestFailureMessage={requestFailureMessage}
                                />
                            )}
                        </div>
                        <div className={style.pageContent}>
                            <ErrorBoundary FallbackComponent={ErrorMessage}>{props.children}</ErrorBoundary>
                        </div>
                    </div>
                    {props.footerVisibility ? (
                        <div className={classNames(style.layoutPadding, style.pageFooter)}>
                            <ButtonContainer alignment="LEFT" noSpacingTop={true}>
                                {props.buttons}
                                <Button
                                    variant={props.primaryButtonRequired ? "PRIMARY" : "SECONDARY"}
                                    type={"button"}
                                    onClick={handleCloseClick}
                                    testId={testIds.common.subpageView.cancelButton}
                                >
                                    {props.primaryButtonRequired ? t("Common.ok") : t("Common.cancel")}
                                </Button>
                            </ButtonContainer>
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
};
