import * as React from "react";

import style from "components/home/additioncard/additioncard.scss";
import { SoftwarePackageHomePage } from "domain/softwarePackages";

import download from "assets/images/icons/productIcons/download.svg";
import manual from "assets/images/icons/productIcons/manual.svg";

interface Props {
    softwarePackage: SoftwarePackageHomePage;
}

const AdditionCard = (props: Props): JSX.Element => {
    return (
        <div className={style.additionCard}>
            <div className={style.topRow}>
                <div className={style.name}>{props.softwarePackage.productName}</div>
                <div className={style.version}>
                    <span>5.75.15</span>
                </div>
            </div>
            <div className={style.bottomRow}>
                <button>
                    <img src={download} alt="" />
                </button>
                <button>
                    <img src={manual} alt="" />
                </button>
            </div>
        </div>
    );
};

export default AdditionCard;
