import * as React from "react";
import { connect } from "react-redux";

import ChooseWorkflowTemplate from "components/home/choose-workflow-template/ChooseWorkflowTemplate";
import InstallBmde from "components/home/install-bmde/InstallBmde";
import CreateBootableUsb from "components/home/onboarding-create-bootable-usb/CreateBootableUsb";
import RunErasure from "components/home/onboarding-run-erasure/RunErasure";
import ViewReport from "components/home/onboarding-view-report/ViewReport";
import { checkIfMenuExpanded, checkIfStepIsClickable, isStepCompleted, WorkflowTemplateData } from "domain/onboardings";
import { Versions } from "domain/softwarePackages";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    lastCompletedStepIndex: number;
    setLastCompletedStepIndex: (value: number) => void;
    defaultWorkflowTemplate: WorkflowTemplateData;
    requestFailureMessage?: string;
    licenses: Versions[];
}

const BmdeSteps = (props: Props): JSX.Element => {
    return (
        <>
            <ChooseWorkflowTemplate
                menuExpanded={checkIfMenuExpanded(props.lastCompletedStepIndex, 0)}
                stepCompleted={isStepCompleted(0, props.lastCompletedStepIndex)}
                stepActive={true}
                setLastCompletedStepIndex={props.setLastCompletedStepIndex}
                defaultWorkflowTemplate={props.defaultWorkflowTemplate}
                requestFailureMessage={props.requestFailureMessage}
            />
            <CreateBootableUsb
                menuExpanded={checkIfMenuExpanded(props.lastCompletedStepIndex, 1)}
                stepCompleted={isStepCompleted(1, props.lastCompletedStepIndex)}
                stepActive={false}
                setLastCompletedStepIndex={props.setLastCompletedStepIndex}
                licenses={props.licenses}
                stepClickable={checkIfStepIsClickable(1, props.lastCompletedStepIndex)}
            />
            <InstallBmde
                menuExpanded={checkIfMenuExpanded(props.lastCompletedStepIndex, 2)}
                stepCompleted={isStepCompleted(2, props.lastCompletedStepIndex)}
                stepActive={false}
                stepClickable={checkIfStepIsClickable(2, props.lastCompletedStepIndex)}
            />
            <RunErasure
                menuExpanded={checkIfMenuExpanded(props.lastCompletedStepIndex, 3)}
                stepCompleted={isStepCompleted(3, props.lastCompletedStepIndex)}
                stepActive={false}
                stepClickable={checkIfStepIsClickable(3, props.lastCompletedStepIndex)}
            />
            <ViewReport
                menuExpanded={checkIfMenuExpanded(props.lastCompletedStepIndex, 4)}
                stepCompleted={isStepCompleted(4, props.lastCompletedStepIndex)}
                stepActive={false}
                stepClickable={checkIfStepIsClickable(4, props.lastCompletedStepIndex)}
            />
        </>
    );
};

export default connector(BmdeSteps);
