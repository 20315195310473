import { McsResponseDto, McTable, toMcsData } from "domain/mc";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

const API_PATH = "/v1/mcs";

class McService {
    public async fetchData(abortController: AbortController): Promise<McTable> {
        return await apiGatewayService
            .invokeApi(API_PATH, "GET", null, {
                abortSignal: abortController.signal,
                apiType: ApiType.OLIVER,
            })
            .then((dto: McsResponseDto) => {
                return {
                    cursor: dto.cursor,
                    mcdata: dto.mcs.map(toMcsData),
                };
            })
            .catch(() => {
                return Promise.reject("Failed to fetch Mcs data.");
            });
    }
}

export const mcService = new McService();
