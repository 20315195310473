import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { GettingStartedLayout } from "./GettingStartedLayout";
import { OnboardingProductType } from "domain/onboardings";
import { Versions } from "domain/softwarePackages";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    licenses: Versions[];
}
export const GettingStarted = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    return (
        <>
            <GettingStartedLayout
                product={OnboardingProductType.BDE}
                visible={true}
                footerVisibility={false}
                extraHeaderText={t("Onboarding.gettingStartedFlow.blanccoDriveErasureProduct")}
                title={t("Onboarding.gettingStartedFlow.title")}
                description={t("Onboarding.gettingStartedFlow.flowDescription")}
                lastCompletedStep={""} // TODO BCC-4403 Pass last completed step from API
                licenses={props.licenses}
                hasIbr={true}
            />
        </>
    );
};

export default connector(GettingStarted);
