import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";

import style from "components/header/header.scss";
import DarkModeSwitchIcon from "components/icons/DarkModeSwitchIcon";
import Tooltip from "components/tooltip/Tooltip";
import { ThemeMode } from "domain/themeMode";
import * as settingRepository from "services/settings/settingsRepository";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import { applyMode } from "store/theme";

interface Props {
    textOnly?: boolean;
}

const mapState = () => ({});
const connector = connect(mapState, { applyMode });

const DarkModeSwitch = (props: ConnectedProps<typeof connector> & Props): JSX.Element => {
    const { t } = useTranslation();
    const [mode, setModeState] = React.useState<ThemeMode>(settingRepository.getThemeMode());
    const tooltip =
        settingRepository.getThemeMode() == ThemeMode.DARK ? t("dakrModeSwitch.light") : t("dakrModeSwitch.dark");
    const toggleDarkMode = () => {
        const themeMode = settingRepository.getThemeMode() == ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK;
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.CHANGE_USER_THEME,
            label: themeMode,
        });
        setModeState(themeMode);
        settingRepository.setThemeMode(themeMode);
        props.applyMode();
    };
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    return (
        <>
            <Tooltip content={tooltip}>
                <button onClick={toggleDarkMode} className={style.button}>
                    {props.textOnly ? (
                        <span>{tooltip}</span>
                    ) : (
                        <div className={style.darkModeSwitchIcon}>
                            <DarkModeSwitchIcon color={theme.iconFillColor} darkMode={mode === ThemeMode.DARK} />
                        </div>
                    )}
                </button>
            </Tooltip>
        </>
    );
};

export default connector(DarkModeSwitch);
