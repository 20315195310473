import classNames from "classnames";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";

import Button from "components/button/Button";
import style from "components/home/bde-home-page/getting-started.scss";
import Chevron from "components/icons/Chevron";
import { StoreState } from "store";

interface Props {
    success: boolean;
    showStep: boolean;
    clickEvent?: (value: boolean) => void;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
});

const connector = connect(mapState);

const StepsCompletedText = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const chevronClass: string = classNames(style.arrowButton, {
        [style.arrowButtonDown]: !props.showStep,
        [style.arrowButtonUp]: props.showStep,
        [style.succcessArrowButton]: props.success && !props.showStep,
    });
    const clickHandler = () => {
        props.clickEvent ? props.clickEvent(true) : null;
    };
    return (
        <>
            <div className={classNames(style.arrow, style.stepWidthAlignment)}>
                <Button variant="TERTIARY" onClick={clickHandler}>
                    <Chevron color={props.theme.activeForegroundColor} className={chevronClass} />
                </Button>
            </div>
        </>
    );
};

export default connector(StepsCompletedText);
