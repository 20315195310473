import * as React from "react";

import style from "./support.scss";

import homeContactSupport from "assets/images/icons/homeContactSupport.svg";
import homeKnowledgeBase from "assets/images/icons/homeKnowledgeBase.svg";

const Support = (): JSX.Element => {
    return (
        <div className={style.support}>
            <div>
                <div className={style.supportLabel}>SUPPORT</div>
                <div className={style.needHelp}>Need help?</div>
            </div>
            <div className={style.item}>
                <img src={homeKnowledgeBase} alt="" />
                <button onClick={() => window.open("https://support.blancco.com/space/KB", "_blank")}>
                    <strong>Knowledge Base</strong>
                </button>
            </div>
            <div className={style.spacer}></div>
            <div className={style.item}>
                <img src={homeContactSupport} alt="" />
                <button onClick={() => window.open("https://support.blancco.com/", "_blank")}>
                    <strong>Contact Support</strong>
                </button>
            </div>
        </div>
    );
};

export default Support;
