import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import CircleWithNumber from "../CircleWithNumber";
import style from "components/home/bde-home-page/getting-started.scss";
import Tooltip from "components/tooltip/Tooltip";
import { StoreState } from "store";

import infoBadge from "assets/images/icons/productIcons/infoBadge.svg";

interface Props {
    iconNumber: number;
    translationKey: string;
    className?: string;
    subStep: boolean;
    infoIcon?: boolean;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
});

const connector = connect(mapState);

const SubStep = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={style.subStepsToFollowLayout}>
            <div className={style.stepWidthAlignment}>
                {<CircleWithNumber number={props.iconNumber} subStep={props.subStep} />}
            </div>
            <div className={props.className || style.stepHeading}>
                {t(props.translationKey)}
                {props.infoIcon && (
                    <Tooltip
                        content={t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.infoIcon")}
                        placement={"right-end"}
                    >
                        <img src={infoBadge} alt="" />
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default connector(SubStep);
