import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import ProductCard from "./ProductCard";
import SubStep from "./SubStep";
import style from "components/home/bde-home-page/getting-started.scss";
import HalfProgressIcon from "components/icons/HalfProgressIcon";
import SelectedCheckmark from "components/icons/SelectedCheckmark";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

interface Props {
    setCloseInfoDialog: (value: boolean) => void;
    setShowContent: (value: boolean) => void;
    setSuccess: (value: boolean) => void;
    success: boolean;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
});

const connector = connect(mapState);

const TRANSLATION_KEYS = {
    TITLE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.title",
    STEPS: {
        ONE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepOne",
        TWO: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepTwo",
        THREE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepThree",
        FOUR: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepFour",
        FIVE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepFive",
    },
    PRODUCTS: {
        CONFIG_TOOL: {
            TITLE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.configurationTool",
            DESCRIPTION: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.configurationToolDescription",
        },
        ERASURE_FILE: {
            TITLE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.erasureFile",
            DESCRIPTION: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.erasureFileDescription",
        },
    },
};

const StepsToFollow = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();

    const handleConfigToolDownload = () => {
        // Implementation for config tool download
    };

    const handleErasureFileDownload = () => {
        // Implementation for erasure file download
    };
    return (
        <>
            <div className={style.infoBox}>
                <div className={style.subStepsToFollowLayout}>
                    <div className={style.stepWidthAlignment}>
                        <HalfProgressIcon />
                    </div>
                    <div className={style.subStepsHeading}>
                        {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.title")}
                    </div>
                </div>
                <SubStep iconNumber={1} translationKey={TRANSLATION_KEYS.STEPS.ONE} subStep={true} />
                <div className={style.portalContent}>
                    <ProductCard
                        titleKey={TRANSLATION_KEYS.PRODUCTS.CONFIG_TOOL.TITLE}
                        descriptionKey={TRANSLATION_KEYS.PRODUCTS.CONFIG_TOOL.DESCRIPTION}
                        onDownload={handleConfigToolDownload}
                    />

                    <ProductCard
                        titleKey={TRANSLATION_KEYS.PRODUCTS.ERASURE_FILE.TITLE}
                        descriptionKey={TRANSLATION_KEYS.PRODUCTS.ERASURE_FILE.DESCRIPTION}
                        onDownload={handleErasureFileDownload}
                    />
                </div>
                <SubStep iconNumber={2} translationKey={TRANSLATION_KEYS.STEPS.TWO} subStep={true} />

                <SubStep iconNumber={3} translationKey={TRANSLATION_KEYS.STEPS.THREE} subStep={true} />

                <div className={style.portalContent}>
                    <div className={style.hostName}>
                        <strong>{t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.hostname")}</strong>
                        {props.user?.erasureClientEndpoint}
                    </div>
                    <div className={style.url}>
                        <strong>{t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.port")}</strong>
                        {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.443")}
                    </div>
                </div>

                <SubStep iconNumber={4} translationKey={TRANSLATION_KEYS.STEPS.FOUR} subStep={true} infoIcon={true} />

                <SubStep iconNumber={5} translationKey={TRANSLATION_KEYS.STEPS.FIVE} subStep={true} />
                <div className={style.markComplete}>
                    {props.success ? (
                        <div className={style.templateSelectedBlock}>
                            <SelectedCheckmark color={props.theme.defaultStarColor} />
                            <div className={style.templateSelected}>
                                {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.completed")}
                            </div>
                        </div>
                    ) : (
                        <button
                            className={classNames(buttons.primaryButtonWithoutIcon)}
                            onClick={() => {
                                props.setCloseInfoDialog(true), props.setShowContent(false), props.setSuccess(true);
                            }}
                        >
                            {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.markComplete")}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default connector(StepsToFollow);
