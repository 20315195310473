import { TemplateTableData } from "domain/workflowTemplates";

export enum BmdeOnboardingSteps {
    STEP_WORKFLOW_TEMPLATE = "WORKFLOW_TEMPLATE",
    STEP_BOOTING_USB_DRIVE = "BOOTING_USB_DRIVE",
    STEP_INSTALLING_BMDE = "INSTALLING_BMDE",
    STEP_RUNNING_ERASURE = "RUNNING_ERASURE",
    STEP_VIEWING_REPORT = "VIEWING_REPORT",
}

export enum BdeOnboardingSteps {
    STEP_WORKFLOW_TEMPLATE = "WORKFLOW_TEMPLATE",
    STEP_PRE_CONFIGURATION = "PRE_CONFIGURATION",
    STEP_BOOTING_USB_DRIVE = "BOOTING_USB_DRIVE",
    STEP_RUNNING_ERASURE = "RUNNING_ERASURE",
    STEP_VIEWING_REPORT = "VIEWING_REPORT",
}

export const BMDE_ONBOARDING_STEPS = Object.values(BmdeOnboardingSteps);

export const BDE_ONBOARDING_STEPS = Object.values(BdeOnboardingSteps);

export function isStepCompleted(focusedStepIndex: number, lastCompletedStepIndex: number): boolean {
    return focusedStepIndex <= lastCompletedStepIndex;
}

export function getValidLastCompletedStep(product: string, lastCompletedStep: string) {
    if (product == OnboardingProductType.BMDE) {
        return mapCompletedStepToBmdeOnboardingStep(lastCompletedStep);
    }
    return mapCompletedStepToBdeOnboardingStep(lastCompletedStep);
}

export function getLastCompletedStepIndexForBde(step: BdeOnboardingSteps | undefined) {
    if (!step) {
        return 0;
    }
    return BDE_ONBOARDING_STEPS.indexOf(step);
}

export function getLastCompletedStepIndexForBmde(step: BmdeOnboardingSteps | undefined) {
    if (!step) {
        return 0;
    }
    return BMDE_ONBOARDING_STEPS.indexOf(step);
}

export function getActiveStep(lastCompletedStepIndex: number): number {
    return lastCompletedStepIndex + 1;
}

export function isStepActive(lastCompletedStepIndex: number, currentStep: number): boolean {
    const activeStep = getActiveStep(lastCompletedStepIndex);
    return activeStep === currentStep;
}

export const mapCompletedStepToBmdeOnboardingStep = (lastCompletedStep: string) => {
    return BMDE_ONBOARDING_STEPS.find((step) => step === lastCompletedStep);
};

export const mapCompletedStepToBdeOnboardingStep = (lastCompletedStep: string) => {
    return BDE_ONBOARDING_STEPS.find((step) => step === lastCompletedStep);
};

export enum OnboardingProductType {
    BDE = "BDE",
    BMDE = "BMDE",
}

export const SUB_STEPS_INSTALL_BMDE = [
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.prepareYourPC",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.plugInBootableUSB",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.turnOnOrReboot",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.clickPermanent",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.clickAutomaticPartitioning",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.setAdminPassword",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.synchronize",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.setupWifiConfiguration",
];

export function checkIfStepIsClickable(stepIndex: number, lastCompletedStepIndex: number) {
    return (
        stepIndex === lastCompletedStepIndex ||
        stepIndex === getActiveStep(lastCompletedStepIndex) ||
        lastCompletedStepIndex === 4
    );
}

export function displayProgressBarProgress(lastCompletedStepIndex: number, previousProgress: number) {
    // TODO BCC-4404 Update logic for progress for the last step i.e "View report"
    if (lastCompletedStepIndex === -1) {
        return previousProgress;
    } else if (lastCompletedStepIndex === 0) {
        return previousProgress + 10;
    } else {
        return previousProgress + 20;
    }
}

export function checkIfMenuExpanded(lastCompletedStepIndex: number, currentStepIndex: number) {
    if (lastCompletedStepIndex === -1 || lastCompletedStepIndex === 4) {
        return false;
    }
    return (
        lastCompletedStepIndex !== -1 &&
        currentStepIndex > lastCompletedStepIndex &&
        isStepActive(lastCompletedStepIndex, currentStepIndex)
    );
}

export interface WorkflowTemplateData {
    name: string;
    description: string;
}

export function toWorkflowTemplateData(dto: TemplateTableData): WorkflowTemplateData {
    return {
        name: dto.name,
        description: dto.description,
    };
}
