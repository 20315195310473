import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Button } from "rsuite";

import style from "components/home/bde-home-page/getting-started.scss";
import { displayStepCompletionIcon } from "components/home/bmde-home-page/GettingStarted";
import Chevron from "components/icons/Chevron";
import arrowStyle from "components/navigation/navigation-toggle-panel.scss";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    menuExpanded: boolean;
    stepCompleted: boolean;
    stepActive: boolean;
    stepClickable: boolean;
}

const InstallBmde = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const chevronClass: string = classNames(arrowStyle.arrow, { [arrowStyle.down]: props.menuExpanded });

    return (
        <>
            <div>
                <div className={classNames(style.stepsToFollowLayout)}>
                    <div className={style.stepWidthAlignment}>
                        {displayStepCompletionIcon(
                            props.menuExpanded,
                            props.stepCompleted,
                            3,
                            props.stepActive,
                            theme.defaultStarColor,
                            theme.whiteColor,
                            theme.secondaryWarningBackgroundColor,
                            theme.descriptionTextColor
                        )}
                    </div>
                    <div className={style.stepHeading}>
                        {t("Onboarding.bmde.gettingStarted.steps.installBMDE.title")}
                    </div>
                    <div className={classNames(style.arrow, style.stepWidthAlignment)}>
                        <Button className={style.arrowButton}>
                            <Chevron color={theme.activeForegroundColor} className={chevronClass} />
                        </Button>
                    </div>
                </div>
                <div>
                    {props.menuExpanded && props.stepClickable ? (
                        <div>{/* //TODO BCC-4404 Update steps for Install BMDE when mockup is ready*/}</div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default connector(InstallBmde);
