import classNames from "classnames";
import { useFeature } from "flagged";
import React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import style from "./mobile-navigation.scss";
import NavigationTogglePanel from "./NavigationTogglePanel";
import sideNavStyle from "./side-navigation.scss";
import PremiumFeatureIcon from "components/icons/PremiumFeatureIcon";
import {
    API_GUIDE_ROUTE,
    API_KEYS_ROUTE,
    CONFIGURATION_BMS_LICENSES_ROUTE,
    DASHBOARD_ROUTE,
    HOME_ROUTE,
    LICENSES_ROUTE,
    MCS_ROUTE,
    OUR_IMPACT_DASHBOARD_ROUTE,
    REPORT_PATHS_ROUTE,
    REPORTS_ROUTE,
    RouteDefinition,
    SUPPORT_AND_HELP_ROUTE,
    SUPPORT_ROUTE,
    SUSTAINABILITY_DASHBOARD_ROUTE,
    TENANTS_ROUTE,
    USERS_ROUTE,
    WORKFLOWS_ROUTE,
} from "components/router/Routes";
import Tooltip from "components/tooltip/Tooltip";
import { FLAG_NEW_NAVIGATION } from "services/feature/FeatureFlagService";
import { StoreState } from "store";

import iconPrivateView from "assets/images/icons/icon_private_view.svg";

interface MainMenuItem extends Partial<RouteDefinition> {
    title: string;
    childRoutes: RouteDefinition[];
    mainRoute?: RouteDefinition;
}

const MobileNavigation = () => {
    const { t } = useTranslation();
    const user = useSelector((state: StoreState) => state.userReducer.user);
    const tenantDetails = useSelector(
        (state: StoreState) => state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1]
    );
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const getTooltip = (route: RouteDefinition, disabled: boolean): JSX.Element => {
        const routes = new Map([
            [
                OUR_IMPACT_DASHBOARD_ROUTE,
                {
                    paid: t("Routes.ourImpact.tooltip.paid"),
                    title: t("Routes.ourImpact.tooltip.unpaid.title"),
                    body: t("Routes.ourImpact.tooltip.unpaid.calculate"),
                    footer: t("Routes.ourImpact.tooltip.unpaid.contactBlancco"),
                },
            ],
            [
                WORKFLOWS_ROUTE,
                {
                    paid: t("Routes.workflowsMenu.paidWorkflowTooltip"),
                    title: t("Routes.workflowsMenu.unpaidTitle"),
                    body: t("Routes.workflowsMenu.automateWorkflowsAndSimplifyCompliance"),
                    footer: t("Routes.workflowsMenu.contactBlancco"),
                },
            ],
        ]);
        const dto = routes.get(route);
        if (dto == null) {
            return <></>;
        }
        if (!disabled) {
            return <div className={style.tooltip}>{dto.paid}</div>;
        }
        return (
            <div className={style.tooltip}>
                <div className={style.title}>{dto.title}</div>
                <div className={style.content}>{dto.body}</div>
                <div>{dto.footer}</div>
            </div>
        );
    };

    // TODO BCC-3711: CONFIGURATION_BMS_LICENSES_ROUTE is enabled as part of license routes in BlanccoCommonCloud.tsx.
    // Check that the route here follows the same visibility logic.
    const mobileMenuItems: MainMenuItem[] = [
        {
            title: HOME_ROUTE.title,
            mainRoute: HOME_ROUTE,
            childRoutes: [],
        },
        {
            title: DASHBOARD_ROUTE.title,
            mainRoute: DASHBOARD_ROUTE,
            childRoutes: [],
        },
        {
            title: SUSTAINABILITY_DASHBOARD_ROUTE.title,
            mainRoute: SUSTAINABILITY_DASHBOARD_ROUTE,
            childRoutes: [],
        },
        {
            title: REPORTS_ROUTE.title,
            mainRoute: REPORTS_ROUTE,
            childRoutes: [],
        },
        {
            title: WORKFLOWS_ROUTE.title,
            mainRoute: WORKFLOWS_ROUTE,
            childRoutes: [],
        },
        {
            title: LICENSES_ROUTE.title,
            mainRoute: LICENSES_ROUTE,
            childRoutes: [],
        },
        {
            title: USERS_ROUTE.title,
            mainRoute: USERS_ROUTE,
            childRoutes: [],
        },
        {
            title: TENANTS_ROUTE.title,
            mainRoute: TENANTS_ROUTE,
            childRoutes: [],
        },
        {
            title: MCS_ROUTE.title,
            mainRoute: MCS_ROUTE,
            childRoutes: [],
        },
        {
            title: CONFIGURATION_BMS_LICENSES_ROUTE.title,
            mainRoute: CONFIGURATION_BMS_LICENSES_ROUTE,
            childRoutes: [],
        },
        {
            title: SUPPORT_ROUTE.title,
            childRoutes: [SUPPORT_AND_HELP_ROUTE, API_GUIDE_ROUTE, API_KEYS_ROUTE, REPORT_PATHS_ROUTE],
        },
    ];

    const hasActiveLink = (routes: RouteDefinition[]): boolean => {
        const isRouteOrChildActive = (route: RouteDefinition): boolean => {
            if (route.path === window.location.pathname) {
                return true;
            }

            return route.childRoutes.some((childRoute) => isRouteOrChildActive(childRoute));
        };

        return routes.some((route) => isRouteOrChildActive(route));
    };

    const getMenuItem = (item: MainMenuItem) => mobileMenuItems.find((menuItem) => menuItem.title === item.title);

    const createMenuItems = (route: RouteDefinition, index: number) => {
        let disabled = false;
        const flag = route.getFeatureFlag();

        if (flag != null && !useFeature(flag)) {
            return null;
        }

        if (!route.isAccessibleTo(tenantDetails)) {
            if (!route.advertised) {
                return null;
            }
            disabled = true;
        }

        if (!route.isValidUser(user)) {
            return null;
        }

        if (route.childRoutes && route.childRoutes.length > 0 && !disabled) {
            return <li key={route.path}>{createMenuStructure(route)}</li>;
        }

        return (
            <li key={route.path} data-index={index}>
                {disabled ? (
                    <div className={classNames(style.navItem, style.inActive)} data-testid={route.testId}>
                        <div>{t(route.title)}</div>
                        <Tooltip maxWidth={218} content={getTooltip(route, disabled)} placement="top-start">
                            <div className={style.icon}>
                                <PremiumFeatureIcon color={theme.iconFillColor} />
                            </div>
                        </Tooltip>
                    </div>
                ) : (
                    <NavLink to={route.path} data-testid={route.testId} className={style.navItem}>
                        {t(route.title)}
                        {route.advertised && (
                            <Tooltip content={getTooltip(route, disabled)} placement="top">
                                <div className={style.icon}>
                                    <div className={style.purchased} />
                                </div>
                            </Tooltip>
                        )}
                        {route.isShared === false && (
                            <Tooltip content={t("CreateReportView.privateView.title")} placement="top">
                                <div className={style.icon}>
                                    <img
                                        className={sideNavStyle.iconPrivateView}
                                        src={iconPrivateView}
                                        alt={t("AltText.privateView")}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </NavLink>
                )}
            </li>
        );
    };

    const createMenuStructure = (route: MainMenuItem | RouteDefinition) => {
        const routeId = route.path ?? route.title;
        const currentRoute = getMenuItem(route)?.mainRoute ?? route;
        const isTopLevelLink = currentRoute.childRoutes.length <= 1;

        return (
            <ul key={route.title}>
                {isTopLevelLink ? (
                    <li className={style.navButton}>
                        <NavLink
                            to={currentRoute.path ?? ""}
                            data-testid={currentRoute.testId}
                            className={style.navItem}
                            activeClassName={"active"}
                        >
                            {t(route.title)}
                        </NavLink>
                    </li>
                ) : (
                    <li>
                        {/* TODO BCC-3711: Close the NavigationTogglePanel when another is opened. */}
                        <NavigationTogglePanel
                            isOpen={hasActiveLink(currentRoute.childRoutes)}
                            id={routeId}
                            openId={hasActiveLink(currentRoute.childRoutes) ? routeId : undefined}
                            title={t(currentRoute.title)}
                            className={classNames({ [style.navItems]: useFeature(FLAG_NEW_NAVIGATION) })}
                        >
                            <Menu className={style.menu}>
                                <MenuItem className={style.menuItem}>
                                    <ul>
                                        {currentRoute.childRoutes.map((childRoute, index) =>
                                            createMenuItems(childRoute, index)
                                        )}
                                    </ul>
                                </MenuItem>
                            </Menu>
                        </NavigationTogglePanel>
                    </li>
                )}
            </ul>
        );
    };

    return (
        <nav
            className={classNames(style.mobileNavigation, {
                [style.mainNavigation]: useFeature(FLAG_NEW_NAVIGATION),
            })}
        >
            {mobileMenuItems.map((item) => createMenuStructure(item))}
        </nav>
    );
};

export default MobileNavigation;
