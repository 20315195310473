import { TableData } from "./table";

export interface Mcs {
    tenantUuid: string;
    name?: string;
    registrationDate?: string;
    lastSynced?: string;
    status?: McState;
    uuid?: string;
    description?: string;
}

export interface McTable {
    cursor: string;
    mcdata: McsTableData[];
}

export const MC_STATES = ["ACTIVE", "UNREGISTERED", "SYNC_FAILED", "HALTED"] as const;
export type McState = typeof MC_STATES[number];

export interface McsTableData extends TableData {
    name: string;
    registrationDate: string;
    lastSynced: string;
    status: McState;
    uuid: string;
    description: string;
}

export interface McsDataDto {
    tenantUuid: string;
    name: string;
    registrationDate: string;
    lastSynced: string;
    status: McState;
    uuid: string;
    description: string;
}

export interface McsResponseDto {
    mcs: McsDataDto[];
    cursor: string;
}

export function toMcsData(dto: McsDataDto): McsTableData {
    return {
        name: dto.name,
        registrationDate: dto.registrationDate,
        lastSynced: dto.lastSynced,
        status: dto.status,
        uuid: dto.uuid,
        description: dto.description,
    };
}
