import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { object, string } from "yup";

import style from "./add-entitlement.scss";
import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import Info from "components/icons/Info";
import SimpleDeleteIcon from "components/icons/SimpleDeleteIcon";
import { BUNDLES_ADD_ONS } from "components/licenses/bundles";
import {
    createProductIdToNameMap,
    FEATURE_LICENSES,
    HL_ONLY_ENTITLEMENT_LICENSE_TYPES,
    isSubscriptionLicense,
    RESTRICTED_ENTITLEMENT_LICENSE_TYPES,
} from "components/licenses/common";
import { addMoreLicenses } from "components/licenses/delivery-history/DeliveryFormContent";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StaticTable from "components/support/api-guide/StaticTable";
import { deduceTierLicenses, isTokenLicense } from "components/tenants/add-tenant/AddTenantForm";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { Container, EmsConfiguration, EntitlementType, LicenseData } from "domain/licenses";
import { licenseService, ProductRateList } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { getCurrentTenantDetails, isLicensingModelBundleWithToken } from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { isAddOn, isStringBlank } from "utils/commonFunctions";
import { logger } from "utils/logging";

import testIds from "testIds.json";

const DEFAULT_SELECT_CONTAINER_VALUE = "select_container";

interface Props {
    submitEventHandler: (values: FormValues, licenses: LicenseCount[]) => void;
    fetchedTokenRates: ProductRateList[];
    emsConfiguration: EmsConfiguration;
    availableLicenses: LicenseData[];
    slContainers: Container[];
}

export interface LicenseCount {
    productId: string;
    available: number;
    assign: number;
    index: number;
    loading: boolean;
    tokenUsed: number;
    errorMessage?: JSX.Element;
}

export interface FormValues {
    type: string;
    description: string;
    tenantUuid?: string;
    containerId?: string;
}

const connector = connect((state: StoreState) => ({
    tenantUuid: state.userReducer.user?.tenantUuid,
    theme: state.themeReducer.theme,
}));

const PRODUCT_ID_TO_NAME = createProductIdToNameMap();

function createLicenseName(licenseType: string): string {
    return PRODUCT_ID_TO_NAME.get(licenseType) ?? "";
}

function AddEntitlementForm(props: Props & ConnectedProps<typeof connector>): JSX.Element {
    const { t } = useTranslation();
    const DESCRIPTION_MAX_LENGTH = 64;
    const MAXIMUM_ASSIGNED_ENTITLEMENTS = 300_000;

    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [availableLicenses, setAvailableLicenses] = React.useState<LicenseData[]>([]);
    const [blanccoTokenLicense, setBlanccoTokenLicense] = React.useState<LicenseData | undefined>();
    const [selectedLicenses, setSelectedLicenses] = React.useState<LicenseCount[]>([]);
    const [invalidAssignedAmount, setInvalidAssignedAmount] = React.useState(false);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const ALL_PRODUCTS = createProductIdToNameMap();
    const [initialTokenAmount, setInitialTokenAmount] = React.useState(0);
    const [errorFlag, setErrorFlag] = React.useState<boolean>(false);
    const [remainingCount, setRemainingCount] = React.useState<number>();
    const [currentProduct, setCurrentProduct] = React.useState<string>();
    const [assignError, setAssignError] = React.useState<boolean>(false);
    const [entitlementType, setEntitlementType] = React.useState<EntitlementType>(
        props.emsConfiguration.slEntitlements ? EntitlementType.SL_UPDATE : EntitlementType.HL_UPDATE
    );
    const fetchAvailableLicenses = (licenseType: string, index: number) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        setSelectedLicenses(
            selectedLicenses.map((license) => {
                if (license.index === index) {
                    license.loading = true;
                }
                return license;
            })
        );
        licenseService
            .fetchAvailableLicenses(licenseType, abortController)
            .then((data) => {
                setSelectedLicenses(
                    selectedLicenses.map((license) => {
                        if (license.index === index) {
                            license.productId = licenseType;
                            license.available = data.availableLicenses;
                            license.loading = false;
                        }
                        return license;
                    })
                );
            })
            .catch((err) => {
                logger.error("Exception occurred while fetching available licenses: ", err);
                setSelectedLicenses(
                    selectedLicenses.map((license) => {
                        if (license.index == index) {
                            license.loading = false;
                        }
                        return license;
                    })
                );
            });
    };

    React.useEffect(() => {
        reCalculateTokens();
    }, [props.fetchedTokenRates]);

    const submitHandler: FormikConfig<FormValues>["onSubmit"] = async (values, { setSubmitting }) => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE_ENTITLEMENT,
            action: Action.ADD_ENTITLEMENT,
        });
        await props.submitEventHandler(values, selectedLicenses);
        setSubmitting(true);
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const isIncludedInEntitlementType = (licenseType: string) => {
        if (isTokenLicense(licenseType)) {
            return true;
        }
        if (entitlementType != EntitlementType.HL_UPDATE) {
            return (
                !HL_ONLY_ENTITLEMENT_LICENSE_TYPES.includes(licenseType) &&
                !RESTRICTED_ENTITLEMENT_LICENSE_TYPES.includes(licenseType)
            );
        }
        return !RESTRICTED_ENTITLEMENT_LICENSE_TYPES.includes(licenseType);
    };
    const isValidLicense = (license: LicenseData) => {
        return (
            !isNaN(parseInt(license.type)) &&
            license.available > 0 &&
            new Date() < new Date(license.expirationDate) &&
            !isSubscriptionLicense(license.type) &&
            isIncludedInEntitlementType(license.type)
        );
    };
    React.useEffect(() => {
        const tenantLicenses: LicenseData[] = [];
        const deducedTierLicenses = deduceTierLicenses(
            getCurrentTenantDetails().tenantTier,
            true,
            getCurrentTenantDetails().type
        );
        props.availableLicenses.forEach((each: LicenseData) => {
            if (!isValidLicense(each)) {
                return;
            }
            if (!isTokenLicense(each.type) && isIncludedInEntitlementType(each.type)) {
                each.product = ALL_PRODUCTS.get(each.type) || each.product;
                tenantLicenses.push(each);
            } else {
                if (isLicensingModelBundleWithToken() && deducedTierLicenses) {
                    setBlanccoTokenLicense(each);
                    setInitialTokenAmount(each.available);
                    tenantLicenses.push(
                        ...deducedTierLicenses
                            .filter(
                                (tierLicense) =>
                                    !FEATURE_LICENSES.includes(tierLicense) && isIncludedInEntitlementType(tierLicense)
                            )
                            .map((eachTierLicense) => {
                                return {
                                    product: ALL_PRODUCTS.get(eachTierLicense) || eachTierLicense,
                                    type: eachTierLicense,
                                    license: "",
                                    available: each.available,
                                    total: 0,
                                    expirationDate: "",
                                    licenseType: "",
                                    isFeatureLicensePresent: false,
                                };
                            })
                    );
                }
            }
        });
        const sortedTenantLicenses = tenantLicenses.sort((a, b) => a.product.localeCompare(b.product));
        setAvailableLicenses(sortedTenantLicenses);
        const defaultTenantLicense = sortedTenantLicenses[0];
        const filteredSelectedLicenses = selectedLicenses.filter(
            (selected) =>
                typeof sortedTenantLicenses.find((available) => available.type == selected.productId) !== "undefined"
        );
        if (filteredSelectedLicenses.length > 0) {
            setSelectedLicenses(filteredSelectedLicenses);
        } else {
            setSelectedLicenses([
                {
                    productId: defaultTenantLicense.type,
                    available: defaultTenantLicense.available,
                    assign: 1,
                    index: 0,
                    loading: false,
                    tokenUsed:
                        blanccoTokenLicense && !isAddOn(defaultTenantLicense.type)
                            ? fetchTokenRate(defaultTenantLicense.type)
                            : defaultTenantLicense.available,
                },
            ]);
        }
    }, [entitlementType]);

    const changeLicenseType = (productId: string, index: number) => {
        if (isLicensingModelBundleWithToken() && !isAddOn(productId)) {
            setSelectedLicenses(
                selectedLicenses.map((license) => {
                    if (license.index === index) {
                        license.productId = productId;
                    }
                    return license;
                })
            );
            reCalculateTokens();
            return;
        }
        fetchAvailableLicenses(productId, index);
    };

    const hideAddRowButton = () => {
        if (isLicensingModelBundleWithToken()) {
            // Filter add-ons
            const addOnsAvailable = availableLicenses.filter((license) => isAddOn(license.type));
            const addonsSelected = selectedLicenses.filter(
                (license) => isAddOn(license.productId) && license.available > 0
            );

            // Update available add-ons in a single pass
            addOnsAvailable.map((addOn) => {
                const selectedAddOn = addonsSelected.find((selected) => selected.productId === addOn.type);
                return selectedAddOn ? { ...addOn, available: addOn.available - selectedAddOn.assign } : addOn;
            });

            // Check for add-ons not selected
            const unselectedAddOns = addOnsAvailable.filter((addOn) => {
                const selectedAddOn = addonsSelected.find((selected) => selected.productId === addOn.type);
                return selectedAddOn?.available != addOn.available;
            });

            if (blanccoTokenLicense) {
                return (
                    (blanccoTokenLicense.available <= 0 && unselectedAddOns.length == 0) ||
                    availableLicenses.length === selectedLicenses.length
                );
            } else {
                return unselectedAddOns.length == 0 || availableLicenses.length === selectedLicenses.length;
            }
        }

        return availableLicenses.length === selectedLicenses.length;
    };

    const addRow = () => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE_ENTITLEMENT,
            action: Action.ADD_ENTITLEMENT_LICENSE,
        });

        // For the new row, first available license that was not selected previously will be assigned.
        let availableItem: LicenseData | undefined;
        availableLicenses.forEach((item) => {
            if (selectedLicenses.find((selectedLicense) => item.type === selectedLicense.productId) === undefined) {
                availableItem = item;
            }
        });

        // availableItem is undefined in case all the available licenses have been selected.
        // In this case, the button is hidden.
        setSelectedLicenses(
            selectedLicenses.concat([
                {
                    productId: availableItem?.type ?? "",
                    available: availableItem?.available ?? 0,
                    assign: 1,
                    index:
                        (selectedLicenses.length > 0 ? Math.max(...selectedLicenses.map((item) => item.index)) : 0) + 1,
                    loading: false,
                    tokenUsed:
                        blanccoTokenLicense && !isAddOn(availableLicenses[0].type)
                            ? fetchTokenRate(availableLicenses[0].type)
                            : availableLicenses[0].available,
                },
            ])
        );
    };

    const clearAllRows = () => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE_ENTITLEMENT,
            action: Action.REMOVE_ALL_LICENSES,
        });
        setSelectedLicenses([]);
    };

    const removeRow = (index: number) => {
        if (blanccoTokenLicense) {
            selectedLicenses.forEach((license) => {
                if (license.index === index && !isAddOn(license.productId)) {
                    setBlanccoTokenLicense({
                        ...blanccoTokenLicense,
                        available:
                            blanccoTokenLicense.available +
                            (isNaN(license.assign) ? 0 : license.assign) * fetchTokenRate(license.productId),
                    });
                }
            });
        }

        setSelectedLicenses(selectedLicenses.filter((license) => license.index !== index));
    };

    const createAssignAmountErrorMessage = (
        amount: number,
        available: number,
        index: number,
        forCommonLicense: boolean
    ) => {
        let message = "";
        if (isNaN(amount)) {
            message = t("AddEntitlementForm.table.assignEntitlementsRequired");
        }
        if (amount < 1) {
            message = t("AddEntitlementForm.table.minimumNumberOfAssignedEntitlements");
        }
        const maximum = Math.min(MAXIMUM_ASSIGNED_ENTITLEMENTS, available);
        if (amount > maximum) {
            setAssignError(true);
            setErrorFlag(forCommonLicense);
            message = t("AddEntitlementForm.table.maximumNumberOfAssignedEntitlements", {
                maximumAmount: maximum,
            });
        } else {
            setErrorFlag(false);
            setAssignError(false);
        }

        if (isStringBlank(message)) {
            setInvalidAssignedAmount(false);
            setSelectedLicenses(
                selectedLicenses.map((license) => {
                    if (license.index === index) {
                        license.errorMessage = <></>;
                    }
                    return license;
                })
            );
            return;
        }
        setInvalidAssignedAmount(true);
        setSelectedLicenses(
            selectedLicenses.map((license) => {
                if (license.index === index) {
                    license.errorMessage = (
                        <div
                            className={classNames(form.error, form.errorMultiLine)}
                            data-testid={
                                testIds.workArea.license.entitlements.createEntitlementDialog.licensesTable
                                    .assignedAmountInput.errorLabel
                            }
                        >
                            <span className={style.text}>{message}</span>
                        </div>
                    );
                }
                return license;
            })
        );
    };

    const reCalculateTokens = () => {
        if (blanccoTokenLicense) {
            if (selectedLicenses.length == 0) {
                setBlanccoTokenLicense((prevBlanccoTokenLicense: LicenseData) => {
                    return {
                        ...prevBlanccoTokenLicense,
                        available: initialTokenAmount,
                    };
                });
            }

            setBlanccoTokenLicense((prevBlanccoTokenLicense: LicenseData) => {
                const totalTokensToDeduct = selectedLicenses.reduce((accumulatedToken, license) => {
                    const productTokenRate = fetchTokenRate(license.productId);

                    if (isNaN(license.assign)) {
                        return accumulatedToken;
                    }
                    return accumulatedToken + license.assign * productTokenRate;
                }, 0);

                return {
                    ...prevBlanccoTokenLicense,
                    available: initialTokenAmount - totalTokensToDeduct,
                };
            });
        }
    };

    const computeAvailableAmount = (availableLicenses: number, assignedAmount: number) => {
        if (isNaN(assignedAmount)) {
            return availableLicenses;
        } else {
            return availableLicenses - assignedAmount;
        }
    };

    const computeAvailableTokenAmount = (productId: string, assignedAmount: number) => {
        if (isNaN(assignedAmount)) {
            return 0;
        } else {
            return fetchTokenRate(productId) * assignedAmount;
        }
    };

    const disableButton = (): boolean => {
        return selectedLicenses.length < 1 || invalidAssignedAmount || errorFlag || assignError;
    };

    const fetchTokenRate = (productId: string) => {
        const product = props.fetchedTokenRates?.find((p) => p.productId === productId);
        return product ? Number(product.rate) : 0;
    };

    // Helper functions
    const renderTokenLicenseContent = (license: LicenseCount) => {
        if (isAddOn(license.productId)) {
            return <>{isNaN(license.assign) ? "0" : license.assign.toString()}</>;
        }
        return <div>{computeAvailableTokenAmount(license.productId, license.assign).toString()}</div>;
    };

    const renderNonTokenLicenseContent = (license: LicenseCount) => {
        return computeAvailableAmount(license.available, license.assign).toString();
    };

    return (
        <Formik
            initialValues={{
                type: props.emsConfiguration.slEntitlements ? "SL_UPDATE" : "HL_UPDATE",
                description: "",
                containerId: "",
            }}
            onSubmit={submitHandler}
            validationSchema={object().shape({
                type: string().required(t("AddEntitlementForm.typeRequired")),
                description: string().max(DESCRIPTION_MAX_LENGTH),
                containerId: string().when("type", {
                    is: EntitlementType.SL_UPDATE,
                    then: string()
                        .required(t("AddEntitlementForm.containerRequired"))
                        .test(
                            "Something selected",
                            t("AddEntitlementForm.containerRequired"),
                            (value) => value !== DEFAULT_SELECT_CONTAINER_VALUE
                        ),
                    otherwise: string().optional(),
                }),
            })}
            validateOnChange={true}
            validateOnBlur={true}
        >
            {({ values, errors, handleChange, handleBlur, isSubmitting }: FormikProps<FormValues>) => {
                if (isSubmitting) {
                    return <LoadingIndicator />;
                }

                const cells = selectedLicenses.map((license) => {
                    let addOn = isAddOn(license.productId);

                    return [
                        <>
                            <select
                                className={classNames(style.formElement, form.select)}
                                id={"productId" + license.index}
                                name={"productId" + license.index}
                                key={"productId" + license.index}
                                onChange={(e) => {
                                    usageStatisticsService.sendEvent({
                                        category: Category.LICENSE_ENTITLEMENT,
                                        action: Action.CHANGE_LICENSE_TYPE,
                                        label: license.productId,
                                    });
                                    changeLicenseType(e.target.value, license.index);
                                    addOn = isAddOn(e.target.value);
                                }}
                                defaultValue={license.productId}
                                data-testid={
                                    testIds.workArea.license.entitlements.createEntitlementDialog.licensesTable
                                        .licenseTypeSelect
                                }
                            >
                                {availableLicenses.map((value) => (
                                    <option
                                        key={value.type}
                                        value={value.type}
                                        defaultValue={license.productId}
                                        hidden={
                                            value.type !== license.productId &&
                                            selectedLicenses.find((item) => item.productId === value.type) !== undefined
                                        }
                                    >
                                        {createLicenseName(value.type)}
                                    </option>
                                ))}
                            </select>
                            {blanccoTokenLicense && addOn && (
                                <div className={classNames(style.addOnText)}>{t("Common.addOns")}</div>
                            )}
                        </>,
                        license.loading ? (
                            <LoadingIndicator small={true} />
                        ) : blanccoTokenLicense ? (
                            renderTokenLicenseContent(license)
                        ) : (
                            renderNonTokenLicenseContent(license)
                        ),
                        <div className={classNames(form.formFieldsFlex, style.gridColumns)} key={license.index}>
                            <span>
                                <input
                                    className={classNames(form.input, style.formElement, style.assignInput, {
                                        [form.inputError]:
                                            (license.productId === currentProduct && errorFlag) ||
                                            (isAddOn(license.productId) && assignError),
                                    })}
                                    key={"assign" + license.index}
                                    id={"assign" + license.index}
                                    type="number"
                                    onChange={(e) => {
                                        let assignedValue = 0;
                                        setSelectedLicenses(
                                            selectedLicenses.map((element) => {
                                                if (element.productId == license.productId) {
                                                    element.assign = parseInt(e.target.value);
                                                    element.tokenUsed =
                                                        fetchTokenRate(license.productId) * parseInt(e.target.value);
                                                }
                                                if (!BUNDLES_ADD_ONS.includes(license.productId)) {
                                                    assignedValue += element.assign;
                                                    if (initialTokenAmount < assignedValue) {
                                                        setRemainingCount(assignedValue - license.assign);
                                                        setCurrentProduct(license.productId);
                                                    }
                                                }
                                                return element;
                                            })
                                        );
                                        if (
                                            !isLicensingModelBundleWithToken() ||
                                            (isLicensingModelBundleWithToken() && isAddOn(license.productId))
                                        ) {
                                            createAssignAmountErrorMessage(
                                                license.assign,
                                                license.available,
                                                license.index,
                                                false
                                            );
                                        } else {
                                            if (blanccoTokenLicense) {
                                                createAssignAmountErrorMessage(
                                                    license.assign,
                                                    blanccoTokenLicense.available / fetchTokenRate(license.productId),
                                                    license.index,
                                                    true
                                                );
                                            }
                                        }
                                    }}
                                    min={1}
                                    disabled={license.loading}
                                    defaultValue={license.assign}
                                    data-testid={
                                        testIds.workArea.license.entitlements.createEntitlementDialog.licensesTable
                                            .assignedAmountInput.itself
                                    }
                                />
                                {license.errorMessage}
                            </span>
                            <div
                                key={"deleteRow" + license.index}
                                data-testid={
                                    testIds.workArea.license.entitlements.createEntitlementDialog.deleteRowButton
                                }
                                className={classNames(style.formElement, style.icon)}
                                onClick={() => {
                                    usageStatisticsService.sendEvent({
                                        category: Category.LICENSE_ENTITLEMENT,
                                        action: Action.REMOVE_LICENSE,
                                        label: license.productId,
                                    });
                                    removeRow(license.index);
                                }}
                            >
                                <SimpleDeleteIcon color={props.theme.errorBackgroundColor} />
                            </div>
                        </div>,
                    ];
                });

                return (
                    <Form>
                        <div>
                            <Heading
                                tag="div"
                                variant="SUBTITLE_1"
                                className={style.modalSubHeading}
                                disableTopSpacing={true}
                            >
                                {t("AddEntitlementForm.addEntitlementFormSubtitle")}
                            </Heading>
                            <div className={form.formFields}>
                                <label
                                    htmlFor="type"
                                    className={classNames(form.label, {
                                        [form.inputError]: errors.type,
                                    })}
                                >
                                    {t("AddEntitlementForm.type")}
                                </label>
                                <select
                                    id="type"
                                    className={classNames(form.select, form.fixedWidthInput, {
                                        [form.inputError]: errors.type,
                                    })}
                                    onChange={(e) => {
                                        usageStatisticsService.sendEvent({
                                            category: Category.LICENSE_ENTITLEMENT,
                                            action: Action.CHANGE_ENTITLEMENT_TYPE,
                                            label: e.target.value,
                                        });
                                        handleChange(e);
                                        setEntitlementType(e.target.value as EntitlementType);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.type}
                                    data-testid={
                                        testIds.workArea.license.entitlements.createEntitlementDialog.typeSelect.itself
                                    }
                                >
                                    <>
                                        {props.emsConfiguration.hlEntitlements && (
                                            <option key={EntitlementType.HL_UPDATE} value={EntitlementType.HL_UPDATE}>
                                                {t("Entitlements.type.hlUpdate")}
                                            </option>
                                        )}
                                        {props.emsConfiguration.slEntitlements && (
                                            <option key={EntitlementType.SL_UPDATE} value={EntitlementType.SL_UPDATE}>
                                                {t("Entitlements.type.slUpdate")}
                                            </option>
                                        )}
                                        {props.emsConfiguration.slEntitlements &&
                                            props.emsConfiguration.availableSlActivations > 0 && (
                                                <option
                                                    key={EntitlementType.SL_CREATE}
                                                    value={EntitlementType.SL_CREATE}
                                                >
                                                    {t("Entitlements.type.slCreate")}
                                                </option>
                                            )}
                                    </>
                                </select>
                                <div
                                    className={form.error}
                                    data-testid={
                                        testIds.workArea.license.entitlements.createEntitlementDialog.typeSelect
                                            .errorLabel
                                    }
                                >
                                    <ErrorMessage name="type" />
                                </div>
                                {values.type === EntitlementType.SL_UPDATE ? (
                                    <div className={classNames(form.formFields)}>
                                        <label
                                            htmlFor="containerId"
                                            className={classNames(form.label, {
                                                [form.inputError]: errors.containerId,
                                            })}
                                        >
                                            {t("AddEntitlementForm.containerId")}
                                        </label>
                                        <select
                                            id="containerId"
                                            className={classNames(form.select, form.fixedWidthInput, {
                                                [form.inputError]: errors.containerId,
                                            })}
                                            onChange={(e) => {
                                                usageStatisticsService.sendEvent({
                                                    category: Category.LICENSE_ENTITLEMENT,
                                                    action: Action.CHANGE_ENTITLEMENT_CONTAINER,
                                                });
                                                handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            value={values.containerId}
                                            data-testid={
                                                testIds.workArea.license.entitlements.createEntitlementDialog
                                                    .containerSelect.itself
                                            }
                                        >
                                            <option
                                                key={DEFAULT_SELECT_CONTAINER_VALUE}
                                                value={DEFAULT_SELECT_CONTAINER_VALUE}
                                            >
                                                {t("AddEntitlementForm.selectContainer")}
                                            </option>
                                            {props.slContainers.map((container) => (
                                                <option key={container.containerId} value={container.containerId}>
                                                    {container.name != null && container.name != ""
                                                        ? container.name
                                                        : container.containerId}
                                                </option>
                                            ))}
                                        </select>
                                        <div
                                            className={form.error}
                                            data-testid={
                                                testIds.workArea.license.entitlements.createEntitlementDialog
                                                    .containerSelect.errorLabel
                                            }
                                        >
                                            <ErrorMessage name="containerId" />
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {values.type === EntitlementType.SL_CREATE ? (
                                    <>
                                        <div>
                                            <label
                                                htmlFor="availableSlActivations"
                                                className={classNames(form.label, style.slActivationLabel)}
                                            >
                                                {t("AddEntitlementForm.availableSlActivations")}
                                            </label>
                                            <label id="availableSlActivations" className={form.label}>
                                                {props.emsConfiguration.availableSlActivations}
                                            </label>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="usedSlActivations"
                                                className={classNames(form.label, style.slActivationLabel)}
                                            >
                                                {t("AddEntitlementForm.usedSlActivations")}
                                            </label>
                                            <label id="usedSlActivations" className={form.label}>
                                                {props.emsConfiguration.usedSlActivations}
                                            </label>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className={classNames(form.formFields, form.formFieldsFlex)}>
                                <div className={form.formFieldsAlignItemsTop}>
                                    <span className={form.optional}>{t("Common.optional")}</span>
                                    <label htmlFor="description" className={classNames(form.label)}>
                                        {t("AddEntitlementForm.description")}
                                    </label>
                                </div>
                                <div className={classNames(style.gridRows, form.notes)}>
                                    <textarea
                                        id="description"
                                        className={classNames(form.input, form.fixedWidthInput, form.textAreaHeight)}
                                        onChange={handleChange}
                                        data-testid={
                                            testIds.workArea.license.entitlements.createEntitlementDialog
                                                .descriptionTextArea.itself
                                        }
                                        maxLength={DESCRIPTION_MAX_LENGTH}
                                    />
                                    <span className={classNames(form.optional)}>
                                        {t("AddEntitlementForm.charactersRemaining", {
                                            remainingCharacters: (
                                                DESCRIPTION_MAX_LENGTH - values.description.length
                                            ).toString(),
                                            maximumNumberOfCharacters: DESCRIPTION_MAX_LENGTH.toString(),
                                        })}
                                    </span>
                                </div>
                            </div>
                            <Heading tag="div" variant="SUBTITLE_1" className={style.modalSubHeading}>
                                {t("AddEntitlementForm.addEntitlementFormSubtitle2")}
                            </Heading>
                            {blanccoTokenLicense && (
                                <div>
                                    <span className={style.tokenMessage}>
                                        {t("AddEntitlementForm.tokenMessage", {
                                            tokenAmount: initialTokenAmount.toString(),
                                        })}
                                    </span>
                                </div>
                            )}
                            <StaticTable
                                testId={
                                    testIds.workArea.license.entitlements.createEntitlementDialog.licensesTable.itself
                                }
                                headers={[
                                    {
                                        className: style.columnHeader,
                                        value: t("AddEntitlementForm.table.license"),
                                    },
                                    blanccoTokenLicense
                                        ? {
                                              className: style.columnHeader,
                                              value: (
                                                  <Tooltip content={t("AddEntitlementForm.table.tokenAmountTooltip")}>
                                                      <span className={style.info}>
                                                          <div className={style.infoText}>
                                                              {t("AddEntitlementForm.table.tokenAmount")}
                                                          </div>
                                                          <Info
                                                              borderColor={theme.contentBackgroundColor}
                                                              color={theme.iconFillColor}
                                                          />
                                                      </span>
                                                  </Tooltip>
                                              ),
                                          }
                                        : {
                                              className: style.columnHeader,
                                              value: t("AddEntitlementForm.table.available"),
                                          },
                                    {
                                        className: style.columnHeader,
                                        value: blanccoTokenLicense ? (
                                            <Tooltip content={t("AddEntitlementForm.table.amountToAssignTooltip")}>
                                                <span className={style.info}>
                                                    <div className={style.infoText}>
                                                        {t("AddEntitlementForm.table.amountToAssign")}
                                                    </div>
                                                    <Info
                                                        borderColor={theme.contentBackgroundColor}
                                                        color={theme.iconFillColor}
                                                    />
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            t("AddEntitlementForm.table.amountToAssign")
                                        ),
                                    },
                                ]}
                                cells={availableLicenses.length > 0 ? cells : []}
                            />
                            {availableLicenses.length < 1 ? (
                                <></>
                            ) : (
                                <div className={classNames(style.gridRows, style.buttons)}>
                                    <button
                                        // Prevent this button from acting as a form submit button when it's the only
                                        // enabled button. Reference: https://github.com/jaredpalmer/formik/issues/1610
                                        type="button"
                                        onClick={addRow}
                                        className={classNames(style.link, buttons.textButton)}
                                        data-testid={
                                            testIds.workArea.license.entitlements.createEntitlementDialog.addRowButton
                                        }
                                        hidden={hideAddRowButton()}
                                    >
                                        {addMoreLicenses(
                                            props.theme.contentBackgroundColor,
                                            props.theme.iconFillColor,
                                            t("Common.addMoreLicenses")
                                        )}
                                    </button>

                                    <button
                                        // See the comment for the above button.
                                        type="button"
                                        onClick={clearAllRows}
                                        className={classNames(style.link, buttons.textButton)}
                                        data-testid={
                                            testIds.workArea.license.entitlements.createEntitlementDialog.clearAllButton
                                        }
                                    >
                                        {t("AddEntitlementForm.table.clearAll")}
                                    </button>
                                </div>
                            )}
                        </div>
                        <div>
                            {errorFlag && (
                                <div className={classNames(style.errorText)}>
                                    <FailedRedNotificationIcon
                                        backgroundColor={props.theme.errorIconColor}
                                        iconColor={props.theme.contentBackgroundColor}
                                    />
                                    <span>
                                        {t("AddEntitlementForm.table.maximumNumberOfAssignedTokens", {
                                            availableAmount: initialTokenAmount - (remainingCount as number),
                                            maximumAmount: initialTokenAmount,
                                        })}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className={style.entitlementButtonContainer}>
                            <button
                                type="submit"
                                className={buttons.primaryButtonWithoutIcon}
                                disabled={disableButton()}
                                data-testid={testIds.workArea.license.entitlements.createEntitlementDialog.createButton}
                            >
                                {t("AddEntitlementForm.createEntitlementButton")}
                            </button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default connector(AddEntitlementForm);
