import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AdditionCard from "components/home/additioncard/AdditionCard";
import CurrentPlan from "components/home/currentplan/CurrentPlan";
import style from "components/home/home.scss";
import ProductCard from "components/home/productcard/ProductCard";
import Support from "components/home/support/Support";
import { homepageProductsMappingList } from "components/licenses/common";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Tooltip from "components/tooltip/Tooltip";
import { LicenseData } from "domain/licenses";
import { SoftwarePackageHomePage, SoftwarePackagesData } from "domain/softwarePackages";
import { LicenseResponse, licenseService } from "services/licenses/LicenseService";
import { softwarePackagesService } from "services/software-packages/SoftwarePackagesService";
import { getCurrentTenantDetails } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { TenantDetails } from "store/tenantDetails";

import infoBadge from "assets/images/icons/productIcons/infoBadge.svg";

class LicenseFetch {
    private promise: Promise<LicenseResponse> | null;

    constructor() {
        this.promise = null;
    }

    fetch(): Promise<LicenseResponse> {
        if (this.promise == null) {
            this.promise = licenseService.fetchLicenses(
                undefined,
                undefined,
                userSessionService.userHasAllAuthorities(["AUTH_LICENSE_VIEW"])
            );
        }
        return this.promise;
    }
}

const Home = (): JSX.Element => {
    const [infoProductsState, setInfoProductsState] = useState<SoftwarePackageHomePage[]>([]);
    const [assetProductsState, setAssetProductsState] = useState<SoftwarePackageHomePage[]>([]);
    const [additionalProductsState, setAdditionalProductsState] = useState<SoftwarePackageHomePage[]>([]);

    const [loading, setLoading] = React.useState(false);
    const [tenantData, setTenantData] = React.useState<TenantDetails>();
    const productsMappingList = homepageProductsMappingList();
    const { t } = useTranslation();

    useEffect(() => {
        setTenantData(getCurrentTenantDetails());
        try {
            setLoading(true);
            const LICENSE_FETCH = new LicenseFetch();
            const softwarePackagesPromise = softwarePackagesService.fetchSoftwarePackages();

            Promise.all([softwarePackagesPromise, LICENSE_FETCH.fetch()])
                .then((promises) => {
                    const softwarePackagesData: SoftwarePackagesData = promises[0];
                    const licenseData: LicenseData[] = promises[1].licenses;
                    const mainProducts: SoftwarePackageHomePage[] = [];

                    const addedProductIds = new Set();
                    licenseData.map((license) => {
                        softwarePackagesData.productDownloads.map((product) => {
                            if (
                                product.licenseIds.includes(license.type) &&
                                !addedProductIds.has(product.productName)
                            ) {
                                mainProducts.push(product);
                                addedProductIds.add(product.productName);
                            }
                        });
                    });

                    const infoProducts: Set<SoftwarePackageHomePage> = new Set();
                    const assetProducts: Set<SoftwarePackageHomePage> = new Set();

                    mainProducts.map((pack) => {
                        productsMappingList.map((mapItem) => {
                            if (mapItem.licensesIds.some((item) => pack.licenseIds.includes(item))) {
                                pack.description = mapItem.description;
                                pack.onboarding = mapItem.onboarding;
                                if (mapItem.group == "information") {
                                    infoProducts.add(pack);
                                } else if (mapItem.group == "asset") {
                                    assetProducts.add(pack);
                                }
                            }
                        });
                    });
                    const additionalProducts = mainProducts.filter(
                        (item) => ![...infoProducts, ...assetProducts].includes(item)
                    );

                    setInfoProductsState([...infoProducts]);
                    setAssetProductsState([...assetProducts]);
                    setAdditionalProductsState([...additionalProducts]);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        } catch (e) {
            setLoading(false);
        }
    }, []);

    const assetProductCards =
        assetProductsState.length > 0 ? (
            <div>
                <div className={style.headerWrapper}>
                    <h3>Asset life cycle </h3>
                    <Tooltip content={t("Onboarding.home.assetHeaderLabel")} placement={"right-end"}>
                        <img src={infoBadge} alt="" />
                    </Tooltip>
                </div>
                <div className={style.productCardsWrapper}>
                    {assetProductsState.map((softwarePackage, index) => (
                        <ProductCard key={index} softwarePackage={softwarePackage} />
                    ))}
                </div>
            </div>
        ) : null;

    const informationProductCards =
        infoProductsState.length > 0 ? (
            <>
                <div className={style.headerWrapper}>
                    <h3>Information life cycle</h3>
                    <Tooltip content={t("Onboarding.home.infoHeaderLabel")} placement={"right-end"}>
                        <img src={infoBadge} alt="" />
                    </Tooltip>
                </div>
                <div className={style.productCardsWrapper}>
                    {infoProductsState.map((softwarePackage, index) => (
                        <ProductCard key={index} softwarePackage={softwarePackage} />
                    ))}
                </div>
            </>
        ) : null;

    const additionsProductCards =
        additionalProductsState.length > 0 ? (
            <>
                <h3>Additional tools</h3>
                <div className={style.additionalToolsWrapper}>
                    {additionalProductsState.map((softwarePackage, index) => (
                        <AdditionCard key={index} softwarePackage={softwarePackage} />
                    ))}
                </div>
            </>
        ) : null;

    return (
        <>
            {loading ? (
                <LoadingIndicator />
            ) : (
                <main>
                    <section className={style.planAndSupport}>
                        <CurrentPlan tenantData={tenantData} />
                        <Support />
                    </section>
                    <section className={style.products}>
                        <section className={style.assets}>{assetProductCards}</section>
                        <section className={style.information}>{informationProductCards}</section>
                        <hr />
                        <section className={style.additionalTools}>{additionsProductCards}</section>
                    </section>
                </main>
            )}
        </>
    );
};

Home.defaultProps = {
    cols: { lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 },
    margin: [40, 40],
    containerPadding: [0, 0],
    isBounded: true,
    rowHeight: 1,
    isResizable: true,
};

export default Home;
