import * as React from "react";
import { connect, ConnectedProps } from "react-redux";

import style from "components/home/bde-home-page/getting-started.scss";
import SelectedCheckmark from "components/icons/SelectedCheckmark";
import { StoreState } from "store";

interface Props {
    successText: string;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
});

const connector = connect(mapState);

const StepCompletedText = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    return (
        <>
            <div className={style.templateSelectedBlock}>
                <SelectedCheckmark color={props.theme.accordianSuccessTextColor}></SelectedCheckmark>
                <div className={style.templateSelected}>{props.successText}</div>
            </div>
        </>
    );
};

export default connector(StepCompletedText);
