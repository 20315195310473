import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

import Warning from "components/icons/Warning";
import style from "components/warning/warning-badge.scss";
import { StoreState } from "store";

export const WarningBadge = (props: { note: string }): JSX.Element => {
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    return (
        <div className={classNames(style.warningBanner, style.badgeContainer)}>
            <div className={style.textAndBannerContainer}>
                <Warning color={theme.secondaryWarningBackgroundColor} width={15} height={15} />
                <div className={style.textDisplay}>{props.note}</div>
            </div>
        </div>
    );
};
