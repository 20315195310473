import * as React from "react";
import { useTranslation } from "react-i18next";

import { GettingStarted as BDEGettingStarted } from "components/home/bde-home-page/GettingStarted";
import { GettingStarted as BMDEGettingStarted } from "components/home/bmde-home-page/GettingStarted";
import style from "components/home/productcard/productcard.scss";
import { subpageContext } from "components/layout/ApplicationLayout";
import { SoftwarePackageHomePage } from "domain/softwarePackages";

import download from "assets/images/icons/productIcons/download.svg";
import manual from "assets/images/icons/productIcons/manual.svg";
import wire from "assets/images/icons/productIcons/wire.svg";

import testIds from "testIds.json";

interface Props {
    softwarePackage: SoftwarePackageHomePage;
}

const ProductCard = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const context = React.useContext(subpageContext);

    return (
        <div className={style.productCard}>
            <div className={style.productCardWrapper}>
                <div className={style.item}>
                    <div className={style.name}>{props.softwarePackage.productName}</div>
                    <div className={style.version}>
                        {props.softwarePackage.onboarding ? <img src={wire} alt="" /> : undefined}
                        <span>7.15</span>
                    </div>
                </div>
                {props.softwarePackage.onboarding !== undefined ? (
                    <div className={style.buttonWrapper}>
                        <button
                            className={style.button}
                            data-testid={testIds.homePage.getStarted.button}
                            onClick={() => {
                                context?.setSubpage({
                                    content:
                                        props.softwarePackage.onboarding === "BDE" ? (
                                            <BDEGettingStarted licenses={props.softwarePackage.versions} />
                                        ) : (
                                            <BMDEGettingStarted licenses={props.softwarePackage.versions} />
                                        ),
                                    isModal: true,
                                });
                            }}
                        >
                            {t("Onboarding.home.getStartedButton")}
                        </button>
                    </div>
                ) : (
                    <div className={style.manualDownloadsWrapper}>
                        <img src={download} alt="" />
                        <img src={manual} alt="" />
                    </div>
                )}
            </div>
            <div className={style.description}>
                <span>{props.softwarePackage.description}</span>
            </div>
        </div>
    );
};

export default ProductCard;
